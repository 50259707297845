// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importa los recursos de traducción
import enTranslation from "../locales/en/global.json";
import esTranslation from "../locales/es/global.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
  lng: "es", // idioma por defecto
  fallbackLng: "es", // idioma por defecto en caso de que falte el idioma seleccionado
  interpolation: {
    escapeValue: false, // react ya se encarga de la seguridad
  },
});

export default i18n;
