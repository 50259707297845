import React, { useEffect, useState } from "react";
import "./App.css";
import "./Styles/Header.css";
import "./Styles/AnimacionHeader.css";
import "./Styles/Contactenos.css";
import "./Styles/Animation.css";
import { useTranslation } from "react-i18next";
import "./config/i18n";
import "./Styles/OptionsMobile.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Prueba from "./Pages/Cotizar";
import Nosotros from "./Pages/Nosotros";
import Servicios from "./Pages/Servicios";
import ViewProject from "./Pages/ViewProject";
import Proyectos from "./Pages/Proyectos";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import "./Styles/Lista_Opciones.css";
import "./Styles/CuadrosOpciones.css";
import "./Styles/BtnContacto.css";

function App() {
  const location = useLocation();
  const [initial, setinitial] = useState(true);

  useEffect(() => {
    if (initial) {
      setinitial(false);
    } else {
      // Scroll to the top of the page when the path changes
      window.scrollTo({ top: 650, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="App">
      <Header />

      <GetButtonContacto />

      <GetSectionOptions />

      <Routes>
        <Route path="/">
          <Route index element={<Nosotros />} />
          <Route path="Prueba" element={<Prueba />} />
          <Route path="Nosotros" element={<Nosotros />} />
          <Route path="Servicios" element={<Servicios />} />
          <Route path="Proyectos" element={<Proyectos />} />
          <Route path="ViewProject" element={<ViewProject />} />
          {/* Redirect to / if no route matches */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>

      <GetAnimation />
      <Contactenos />
    </div>
  );
}

function GetSectionOptions() {
  const { t } = useTranslation();
  return (
    <div className="Main2">
      <section id="Section-cuadros-opciones">
        <div className="Container-cuadros-opciones">
          <div className="Container-icono-opciones">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-calendar3"
              viewBox="0 0 16 16"
            >
              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
              <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
          </div>
          <h5 className="Texto-opciones-main">
            {t("SectionOptions.0.experiencia")}
          </h5>

          <Link className="Link-cuadros-opciones" to="/Proyectos">
            {t("SectionOptions.0.msjconoceproyectos")}{" "}
            <i class="bi bi-arrow-right"></i>
          </Link>
        </div>
        <div className="Container-cuadros-opciones">
          <div className="Container-icono-opciones">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-houses"
              viewBox="0 0 16 16"
            >
              <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207z" />
            </svg>
          </div>
          <h5 className="Texto-opciones-main">
            {t("SectionOptions.0.servicios")}
          </h5>

          <Link className="Link-cuadros-opciones" to="/Servicios">
            {t("SectionOptions.0.msjservicio")}{" "}
            <i class="bi bi-arrow-right"></i>
          </Link>
        </div>
        <div className="Container-cuadros-opciones">
          <div className="Container-icono-opciones">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-telephone-plus"
              viewBox="0 0 16 16"
            >
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
              <path
                fill-rule="evenodd"
                d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5"
              />
            </svg>
          </div>
          <h5 className="Texto-opciones-main">
            {" "}
            {t("SectionOptions.0.cotiza")}
          </h5>

          <Link className="Link-cuadros-opciones" to="/Prueba">
            {t("SectionOptions.0.msjcotiza")} <i class="bi bi-arrow-right"></i>
          </Link>
        </div>
      </section>
    </div>
  );
}

function GetAnimation() {
  return (
    <div className="container">
      <p id="P_animation">VIDRIOS ALCASA</p>
      <section className="animation">
        <div className="first">
          <div>Seguridad</div>
        </div>
        <div className="second">
          <div>Calidad</div>
        </div>
        <div className="third">
          <div>Confianza</div>
        </div>
      </section>
    </div>
  );
}

function GetButtonContacto() {
  const [isChatOpen, setChatOpen] = useState(false);
  const { t } = useTranslation();
  const OpenChat = () => {
    setChatOpen(!isChatOpen);
  };

  return (
    <div id="Container-contacto-static">
      <motion.section
        id="Section-contacto-static"
        onClick={OpenChat}
        animate={{
          scale: [1, 1.3, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 2.5,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
        style={{ display: "inline-block" }}
      >
        <svg
          id="Btn-contacto-static"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-whatsapp"
          viewBox="0 0 16 16"
        >
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
        </svg>
      </motion.section>

      {isChatOpen && (
        <div id="Chat-panel">
          <div className="Chat-header">
            <h2>{t("btnContacto.0.tituloComunicarse")}</h2>
            <section>
              <i onClick={OpenChat} class="bi bi-x-circle"></i>
            </section>
          </div>
          <div className="Chat-content">
            <p>{t("btnContacto.0.msjPrecionaBoton")}</p>
          </div>
          <div className="Chat-footer">
            <button>
              {" "}
              <a className="DirijirWhatsApp" href="https://wa.me/50684382898">
                {t("btnContacto.0.msjAbrirChat")}
              </a>{" "}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Header() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    console.log(lng);
  };
  const [item, setItem] = useState(1);

  function ClickItem(pos) {
    setItem(pos);
  }
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const handleLanguageOption = (e) => {
    handleLanguageChange(e.target.value);
  };

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  return (
    <header className="header">
      <div className="Container-links">
        <ul className="Lista-Opciones">
          <section id="Texto-header">
            <h1>Vidrios Alcasa</h1>
          </section>

          <li>
            <button className="btn-toggle" onClick={toggleOffcanvas}>
              Opciones
            </button>
          </li>
          <li>
            <select className="select-idioma" onChange={handleLanguageOption}>
              <option value="" selected="true">
                Seleccionar{" "}
              </option>
              <option value="es">Español</option>
              <option value="en">English</option>
            </select>
          </li>
          <li
            className="Li-opciones hide-on-mobile"
            id="Second-item-link"
            onClick={() => ClickItem(1)}
          >
            <Link
              className={`Link-opciones ${item === 1 ? "Active" : ""}`}
              to="Nosotros"
            >
              {t("header.0.Inicio")}
            </Link>
          </li>
          <li className="Li-opciones" onClick={() => ClickItem(2)}>
            <Link
              className={`hide-on-mobile Link-opciones ${
                item === 2 ? "Active" : ""
              }`}
              to="/Servicios"
            >
              {t("header.0.Servicio")}
            </Link>
          </li>
          <li className="Li-opciones" onClick={() => ClickItem(3)}>
            <Link
              className={`hide-on-mobile Link-opciones ${
                item === 3 ? "Active" : ""
              }`}
              to="/Proyectos"
            >
              {t("header.0.Proyectos")}
            </Link>
          </li>
          <li className="Li-opciones" onClick={() => ClickItem(4)}>
            <Link
              className={`hide-on-mobile Link-opciones ${
                item === 4 ? "Active" : ""
              }`}
              to="/Prueba"
            >
              {t("header.0.Contactenos")}
            </Link>
          </li>
          <li className="Li-opciones hide-on-mobile" id="Last-item-link">
            <p>
              <i class="bi bi-whatsapp"></i> 8438-2898
            </p>
          </li>
        </ul>
      </div>
      <div className="header-background">
        {/* Imagen de fondo */}
        {/* Puedes cambiar la ruta de la imagen según tu proyecto */}
      </div>
      <div className="header-content">
        {/* Contenedor de los títulos */}
        <div className="header-titles">
          <div className="title-wrapper">
            <section>
              <h1 className="header-title Letra-inicial">
                <span id="Letra-inicial-header">V</span>
              </h1>
            </section>
            <h1 className="header-title Continuacion-letra">
              <span>i</span>
              <span>dri</span>
              <span>os</span>
              <span>Al</span>
              <span>ca</span>
              <span>sa</span>
            </h1>
            <h2 className="sub-title Texto-header">
              {t("header.0.mensajeRealidad")}
            </h2>
            <h2 className="sub-title2"> {t("header.0.material")}</h2>
          </div>

          <div className="title-wrappers">
            <motion.h1
              className="header-title2"
              initial={{ y: -150 }}
              animate={{ y: 5 }}
              transition={{ duration: 1.5 }}
            >
              EXTRALUM
            </motion.h1>
            <div
              className="Container_btnContacto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 10, scale: [1, 1.2, 1] }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                opacity: 10,
                repeatDelay: 3,
                delay: 0,
              }}
            >
              <button
                className="btn-contactenos"
                href="https://wa.me/50671715494"
              >
                <a className="DirijirWhatsApp" href="https://wa.me/50671715494">
                  {" "}
                  {t("header.0.Contactenos")}
                </a>{" "}
                <i class="bi bi-whatsapp"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas sidebar */}
      <div
        className={`offcanvas ${isOffcanvasOpen ? "open" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="btn-close" onClick={toggleOffcanvas}>
          &times;
        </button>
        <div className="offcanvas-content">
          <section>
            <h1 style={{ textAlign: "center", marginBottom: "45px" }}>
              Vidrios Alcasa
            </h1>
          </section>
          <ul className="list-options-mobile">
            <li>
              <Link
                className="link-options-movile"
                to="Nosotros"
                onClick={toggleOffcanvas}
              >
                {t("header.0.Inicio")}
              </Link>
            </li>
            <li>
              <Link
                className="link-options-movile"
                to="/Servicios"
                onClick={toggleOffcanvas}
              >
                {t("header.0.Servicio")}
              </Link>
            </li>
            <li>
              <Link
                className="link-options-movile"
                to="/Proyectos"
                onClick={toggleOffcanvas}
              >
                {t("header.0.Proyectos")}
              </Link>
            </li>
            <li>
              <Link
                className="link-options-movile"
                to="/Prueba"
                onClick={toggleOffcanvas}
              >
                {t("header.0.Contactenos")}
              </Link>
            </li>
            <li>
              <p>
                <i class="bi bi-whatsapp"></i> 8438-2898
              </p>
            </li>
            <li>
              <img
                style={{ width: "70%", position: "relative", right: "23%" }}
                src="https://res.cloudinary.com/djxwusqnb/image/upload/v1724874447/mh5lqhpnqkawiq8l7bly.png"
                alt="logo"
              />
            </li>
          </ul>
        </div>
      </div>
      {isOffcanvasOpen && (
        <div className="overlay" onClick={toggleOffcanvas}></div>
      )}
    </header>
  );
}

function Contactenos() {
  const { t } = useTranslation();
  return (
    <div className="Container-contactenos">
      <div className="Container-parrafos">
        <h1> {t("header.0.Contactenos")}</h1>

        <p>{t("ContactenosFooter.0.msjContactenos")}</p>
      </div>
      <div className="Container-telefonos">
        <h4>{t("ContactenosFooter.0.telefono")}:</h4>
        <h5>+506 8438-2898</h5>
        <h5>+506 7171-5494</h5>
      </div>
      <div className="Container-btnContacto">
        <button
          onClick={() => {
            window.location.href = "https://wa.me/50671715494";
          }}
        >
          <a className="DirijirWhatsApp" href="https://wa.me/50684382898">
            {t("header.0.Contactenos")}
          </a>{" "}
          <i class="bi bi-whatsapp"></i>
        </button>
      </div>
    </div>
  );
}

export default App;
