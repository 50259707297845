import "../Styles/Servicios.css";
import { color, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
function Servicios() {
  const { t } = useTranslation();
  return (
    <motion.div
      className="Container-principal-servicios"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 50 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <h1 id="Texto-servicios">{t("Servicios.0.tituloServicios")}</h1>
      <section>
        <GetSistemasServicios />
      </section>
      <section>
        <GetSectionServicios />
      </section>
    </motion.div>
  );
}

const Data = [
  {
    description: {
      en: "Glass Facades",
      es: "Fachadas en Vidrio",
    },
    imagenes: [
      "Imagenes/header3.jpeg",
      "Imagenes/header2.jpg",
      "Imagenes/header.jpg",
      "Imagenes/A_colores.png",
    ],
  },
  {
    description: {
      en: "Glass and Aluminum Gates",
      es: "Portones vidrio y aluminio",
    },
    imagenes: [
      "Imagenes/Portones-vidrio/img1.jpeg",
      "Imagenes/Portones-vidrio/img2.jpeg",
      "Imagenes/Portones-vidrio/img3.jpeg",
      "Imagenes/Portones-vidrio/img4.jpeg",
      "Imagenes/Portones-vidrio/img5.jpeg",
      "Imagenes/Portones-vidrio/img6.jpeg",
      "Imagenes/Portones-vidrio/img7.jpeg",
      "Imagenes/Portones-vidrio/img8.jpeg",
      "Imagenes/Portones-vidrio/img9.jpeg",
    ],
  },
  {
    description: {
      en: "Bathroom doors and screens",
      es: "Puertas de baño y mamparas",
    },
    imagenes: [
      "Imagenes/P-banos/img1.jpeg",
      "Imagenes/P-banos/img2.jpeg",
      "Imagenes/P-banos/img3.jpeg",
      "Imagenes/P-banos/img4.jpeg",
      "Imagenes/P-banos/img5.jpeg",
    ],
  },
  {
    description: {
      en: "All types of windows",
      es: "Todo tipo de ventanas",
    },
    imagenes: [
      "Imagenes/Ventanas/img1.jpeg",
      "Imagenes/Ventanas/img2.jpeg",
      "Imagenes/Ventanas/img3.jpeg",
      "Imagenes/Ventanas/img4.jpeg",
      "Imagenes/Ventanas/img5.jpeg",
      "Imagenes/Ventanas/img6.jpeg",
      "Imagenes/Ventanas/img7.jpeg",
      "Imagenes/Ventanas/img8.jpeg",
      "Imagenes/Ventanas/img9.jpeg",
    ],
  },
  {
    description: {
      en: "Glass balconies and roofs",
      es: "Varandas y techos en vidrio",
    },
    imagenes: [
      "Imagenes/Varandas-techos/img1.jpeg",
      "Imagenes/Varandas-techos/img2.jpeg",
      "Imagenes/Varandas-techos/img3.jpeg",
      "Imagenes/Varandas-techos/img4.jpeg",
      "Imagenes/Varandas-techos/img5.jpeg",
      "Imagenes/Varandas-techos/img6.jpeg",
    ],
  },
  {
    description: {
      en: "Ventilates",
      es: "Ventilas",
    },
    imagenes: [
      "Imagenes/Ventilas/img1.jpeg",
      "Imagenes/Ventilas/img2.jpeg",
      "Imagenes/Ventilas/img3.jpeg",
    ],
  },
  {
    description: {
      en: "Showcases",
      es: "Vitrinas",
    },
    imagenes: [
      "Imagenes/header3.jpeg",
      "Imagenes/header2.jpg",
      "Imagenes/header.jpg",
    ],
  },
  {
    description: {
      en: "Mirrors",
      es: "Espejos",
    },
    imagenes: [
      "Imagenes/header3.jpeg",
      "Imagenes/header2.jpg",
      "Imagenes/header.jpg",
    ],
  },
  {
    description: {
      en: "Fixed and sliding screens",
      es: "Cedazos Fijos y Corredizos",
    },
    imagenes: [
      "Imagenes/header3.jpeg",
      "Imagenes/header2.jpg",
      "Imagenes/header.jpg",
    ],
  },
];

const GetSectionServicios = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language
  const [currentIndexes, setCurrentIndexes] = useState(
    Array(Data.length).fill(0)
  );

  const nextImage = (index) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((imgIndex, i) =>
        i === index ? (imgIndex + 1) % Data[index].imagenes.length : imgIndex
      )
    );
  };

  const prevImage = (index) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((imgIndex, i) =>
        i === index
          ? (imgIndex - 1 + Data[index].imagenes.length) %
            Data[index].imagenes.length
          : imgIndex
      )
    );
  };

  return (
    <div>
      <section className="Section-servicios">
        <ul className="Lista-servicios">
          {Data.map((data, index) => (
            <AnimatedListItem
              key={index}
              data={data}
              currentImageIndex={currentIndexes[index]}
              onNext={() => nextImage(index)}
              onPrev={() => prevImage(index)}
              description={data.description[currentLanguage]}
            />
          ))}
        </ul>
      </section>
      <section id="texto-final-servicios">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.1 }}
        >
          {t("Servicios.0.msjMasServicios")}
        </motion.h1>
      </section>
    </div>
  );
};

const AnimatedListItem = ({
  data,
  currentImageIndex,
  onNext,
  onPrev,
  description,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.li
      className="Li-servicios"
      ref={ref}
      initial={{ opacity: 0.3, x: 0, y: 10, scale: 0.9 }}
      whileInView={{ opacity: 1, x: 0, y: 20, scale: 1 }}
      animate={controls}
      transition={{ duration: 1.1 }}
    >
      <div className="Container-servicios">
        <img
          className="Imagen-servicios"
          src={data.imagenes[currentImageIndex]}
          alt={`imagen-${data.description}`}
        />
        <p style={{ fontSize: "large", fontWeight: "500" }}>{description}</p>
        <div className="navigation-buttons">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-compact-left Btn-Anterior"
            viewBox="0 0 16 16"
            onClick={onPrev}
          >
            <path
              fill-rule="evenodd"
              d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
            />
          </motion.svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-compact-right Btn-Siguiente"
            viewBox="0 0 16 16"
            onClick={onNext}
          >
            <path
              fill-rule="evenodd"
              d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
            />
          </svg>
        </div>
      </div>
    </motion.li>
  );
};

function GetSistemasServicios() {
  const { t } = useTranslation();
  return (
    <div>
      <section className="Section-sistemas">
        <ul className="Lista-sistemas-servicios">
          <li id="Lista-parrafo-sistemas">
            <p>{t("Servicios.0.msjServicios")}</p>
          </li>
          <motion.li
            initial={{ opacity: 0.3, y: 30, scale: 0.5 }}
            whileInView={{ opacity: 2, y: 0, scale: 1 }}
            transition={{ duration: 1.3 }}
          >
            <img
              id="Imagen-vidrios-colores"
              src="Imagenes/V_colores.png"
              alt="main2"
            ></img>
          </motion.li>
        </ul>
      </section>
      <section id="Section-sistemas">
        <ul className="ListaPrincipal-tipos-sistemas">
          <li className="Listas-sistema">
            <div id="Container-textoPrincipal-sistemas">
              <h1>{t("Servicios.0.tituloSistemas")}</h1>
            </div>
            <ul className="Lista-tipos-sistemas">
              <div>
                <ul>
                  <li className="Li-textoPrincipal-sistema">
                    {t("Servicios.0.tituloVentanas")}
                  </li>
                  <li className="Sistema">5020</li>
                  <li className="Sistema">Akary</li>
                  <li className="Sistema">{t("Servicios.0.EuropaTipo")}</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="Li-textoPrincipal-sistema">
                    {t("Servicios.0.tituloPortones")}
                  </li>
                  <li className="Sistema">8025</li>
                  <li className="Sistema">Akary 8040</li>
                  <li className="Sistema"> {t("Servicios.0.EuropaTipo")}</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="Li-textoPrincipal-sistema">
                    {t("Servicios.0.tituloVidrios")}
                  </li>
                  <li className="Sistema">
                    {" "}
                    {t("Servicios.0.Vidrios.0.Temperados")}
                  </li>
                  <li className="Sistema">
                    {t("Servicios.0.Vidrios.0.samblasteados")}
                  </li>
                  <li className="Sistema">
                    {t("Servicios.0.Vidrios.0.Satinados")}
                  </li>
                </ul>
              </div>
            </ul>
          </li>
          <motion.li
            className="Listas-sistema Lista-colores-Al"
            initial={{ opacity: 0.3, y: 30, scale: 0 }}
            whileInView={{ opacity: 2, y: 0, scale: 1 }}
            transition={{ duration: 1.3 }}
          >
            <img
              id="Imagen-aluminio-colores"
              src="Imagenes/A_colores.png"
              alt="main2"
            ></img>
            <div>
              <ul className="Lista-dibujar-colores-sistemas">
                <li className="Lista-colores-sistemas">
                  <p>{t("Servicios.0.Colores")}</p>
                </li>

                <li className="Lista-colores-sistemas color1">
                  <motion.div
                    className="color1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 10, scale: [1, 1.2, 1] }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                      opacity: 10,
                      repeatDelay: 3,
                      delay: 0,
                    }}
                  />
                </li>
                <li className="Lista-colores-sistemas color1">
                  <motion.div
                    className="color2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 10, scale: [1, 1.2, 1] }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                      opacity: 10,
                      repeatDelay: 3,
                      delay: 1,
                    }}
                  />
                </li>
                <li className="Lista-colores-sistemas color1">
                  <motion.div
                    className="color3"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 10, scale: [1, 1.2, 1] }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                      opacity: 10,
                      repeatDelay: 3,
                      delay: 2,
                    }}
                  />
                </li>
                <li className="Lista-colores-sistemas color1">
                  <motion.div
                    className="color4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 10, scale: [1, 1.2, 1] }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                      opacity: 10,
                      repeatDelay: 3,
                      delay: 3,
                    }}
                  />
                </li>
              </ul>
            </div>
          </motion.li>
        </ul>
      </section>
    </div>
  );
}

export default Servicios;
