import { Link, Outlet } from "react-router-dom";
import "../Styles/Contactenos.css";
import { useTranslation } from "react-i18next";

function Prueba() {
  const { t } = useTranslation();
  const LlamadaContacto = () => {
    window.location.href = "https://wa.me/50671715494";
  };

  return (
    <div onClick={() => LlamadaContacto()} id="Container-cotizar">
      <h3 onClick={LlamadaContacto}>
        {t("ContactenosFooter.0.msjObtengaCotizacion")}
      </h3>
    </div>
  );
}

export default Prueba;
