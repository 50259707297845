import "../Styles/Nosotros.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
function Nosotros() {
  const { t } = useTranslation();
  return (
    <motion.div
      id="Container-nosotros"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 50 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <h1>{t("Nosotros.0.TituloQuienesSomos")}</h1>
      <p id="Texto-nosotros">
        {t("Nosotros.0.QuienesSomos1.0.parte1")}{" "}
        <span className="Resaltar-texto-nosotros">
          {" "}
          {t("Nosotros.0.QuienesSomos1.0.parte2")}
        </span>{" "}
        {t("Nosotros.0.QuienesSomos1.0.parte3")},{" "}
        <span className="Resaltar-texto-nosotros">
          {" "}
          {t("Nosotros.0.QuienesSomos1.0.parte4")}
        </span>{" "}
        {t("Nosotros.0.QuienesSomos1.0.parte5")}
      </p>
      <Cualidades />
      <NosotrosServicios />
      <ProyectosMain />
    </motion.div>
  );
}

function Cualidades() {
  const { t } = useTranslation();
  return (
    <div className="Container-cualidades">
      <ul className="Section-cualidades">
        <li className="Lista-texto-cualidades Listas-cualidades1">
          <h1> {t("Nosotros.0.TituloMosion")}</h1>
          <p>{t("Nosotros.0.Mision")}</p>
        </li>
        <li className="Lista-img-cualidades">
          <img src="Imagenes/header2.jpg" alt="main2"></img>
        </li>
      </ul>
      <ul className="Section-cualidades Listas-cualidades2">
        <li className="Lista-img-cualidades">
          <img src="Imagenes/Cualidades-img2.jpg" alt="main2"></img>
        </li>
        <li className="Lista-texto-cualidades">
          <h1>{t("Nosotros.0.TituloEquipo")}</h1>
          <p>{t("Nosotros.0.msjEquipo")}</p>
        </li>
      </ul>
    </div>
  );
}

function NosotrosServicios() {
  const { t } = useTranslation();
  return (
    <div id="ContainerNosotrosServicios">
      <div>
        <h3>{t("Nosotros.0.msjTodoTipo")}</h3>
      </div>
      <section id="SectionNosotrosServicios">
        <div>
          <img src="Imagenes/cualidades1.jpeg" alt="main2"></img>
        </div>
        <div>
          <img src="Imagenes/cualidades2.jpeg" alt="main2"></img>
        </div>
      </section>
      <div>
        <Link id="BtnMasProyectos" to="/Proyectos">
          {t("Nosotros.0.msjConozacaMasServicios")}{" "}
          <i class="bi bi-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
}

function ProyectosMain() {
  return (
    <div id="Container-main-proyectos">
      <section>
        <PresentacionProyectos />
      </section>
    </div>
  );
}

const DataImagenesProyectos = [
  {
    Imagenes: [
      "Imagenes/Proyectos-inicio/lista1-img2.jpeg",
      "Imagenes/Proyectos-inicio/lista1-img3.jpeg",
      "Imagenes/Proyectos-inicio/lista1-img1.jpeg",
    ],
  },
  {
    Imagenes: [
      "Imagenes/Proyectos-inicio/lista2-img2.jpeg",
      "Imagenes/Proyectos-inicio/lista2-img1.jpeg",
      "Imagenes/Proyectos-inicio/lista2-img3.jpeg",
    ],
  },
  {
    Imagenes: [
      "Imagenes/Proyectos-inicio/lista3-img1.jpeg",
      "Imagenes/Proyectos-inicio/lista3-img2.jpeg",
      "Imagenes/Proyectos-inicio/lista3-img3.jpeg",
    ],
  },
  {
    Imagenes: [
      "Imagenes/Proyectos-inicio/lista4-img1.jpeg",
      "Imagenes/Proyectos-inicio/lista4-img2.jpeg",
      "Imagenes/Proyectos-inicio/lista4-img3.jpeg",
    ],
  },
];

function PresentacionProyectos() {
  const { t } = useTranslation();
  return (
    <div className="SectionProyectos">
      <section id="Section-texto-proyectos">
        {" "}
        <h1>{t("Nosotros.0.msjTituloProyecto")}</h1>
      </section>
      <section id="Section-imagenes-main">
        <ImagenesProyectos
          Imagenes={DataImagenesProyectos[0].Imagenes}
          index={0}
        />
        <ImagenesProyectos
          Imagenes={DataImagenesProyectos[1].Imagenes}
          index={1}
        />
        <ImagenesProyectos
          Imagenes={DataImagenesProyectos[2].Imagenes}
          index={2}
        />
        <ImagenesProyectos
          Imagenes={DataImagenesProyectos[3].Imagenes}
          index={3}
        />
      </section>
    </div>
  );
}

function ImagenesProyectos({ Imagenes, index }) {
  const [mainImg, setmainImg] = useState(
    DataImagenesProyectos[index].Imagenes[0]
  );

  const [imgSelect, setSelectimg] = useState(0);

  const DarImg = (item, select) => {
    setmainImg(item);
    setSelectimg(select);
  };

  return (
    <div id="ContainerProyectoMain">
      <section id="ImgMainProyectos">
        <img id="ImgPrincipalProyecto" src={mainImg} alt="main2"></img>
        <ul id="ListaMainProyecto">
          {Imagenes.map((imgSrc, index) => (
            <li key={index}>
              <img
                src={imgSrc}
                alt={`thumbnail-${index}`}
                onClick={() => DarImg(imgSrc, index)}
                className={index === imgSelect ? "selected" : ""}
              />
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Nosotros;
