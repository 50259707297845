import React, { useEffect, useState } from "react";
import ".././Styles/ViewProject.css"; // Ensure to create this CSS file with appropriate styles
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Projects from "../Data/Projects.json";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const ViewProject = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language
  const [searchParams] = useSearchParams();
  const [projectSelected, setProjectSelected] = useState();
  useEffect(() => {
    const projectId = parseInt(searchParams.get("id"), 10); // Asegúrate de convertirlo a número

    const project = Projects.projects.find((p) => p.ID === projectId);
    setProjectSelected(project);
    console.log("ID: ", projectId);
    console.log("Project: ", project);
  }, [searchParams]);

  return (
    <motion.div
      initial={{ opacity: 0.9, y: 0, scale: 0.91 }}
      whileInView={{ opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 1 }}
    >
      {projectSelected && (
        <div className="project-container">
          <section className="project-header">
            <h1>{t("ViewProject.0.tituloAcercaDe")}</h1>
            <p>{t("ViewProject.0.msjAcercaDe")}</p>
            <button className="back-to-projects">
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/Proyectos"
              >
                {t("ViewProject.0.btnVerProyecto")}
              </Link>
            </button>
          </section>

          <section className="project-details">
            <table className="details-table">
              <thead>
                <tr>
                  <th> {t("ViewProject.0.tituloDetalles")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {t("ViewProject.0.ubicacion")}</td>
                  <td className="td-detalles-proyecto">
                    {projectSelected.ubicacion[currentLanguage]}
                  </td>
                </tr>
                <tr>
                  <td> {t("ViewProject.0.Material")}</td>
                  <td className="td-detalles-proyecto">
                    {projectSelected.material[currentLanguage]}
                  </td>
                </tr>
                <tr>
                  <td> {t("ViewProject.0.Aluminio")}</td>
                  <td className="td-detalles-proyecto">
                    {projectSelected.aluminio[currentLanguage]}
                  </td>
                </tr>
                <tr>
                  <td> {t("ViewProject.0.Vidrio")}</td>
                  <td className="td-detalles-proyecto">
                    {projectSelected.vidrio[currentLanguage]}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="project-main-image">
              <img
                style={{ borderRadius: "3%" }}
                src={projectSelected.img}
                alt="Proyecto en Uvita"
              />
              <div className="overlay-ubicacion" style={{ borderRadius: "3%" }}>
                <h2 style={{ color: "white", fontSize: "30px" }}>
                  {t("ViewProject.0.msjProyectoEn")}{" "}
                  {projectSelected.ubicacion[currentLanguage]}
                </h2>
              </div>
            </div>
          </section>

          <section className="project-gallery">
            <h2> {t("ViewProject.0.GaleriaImgs")}</h2>
            <div className="gallery-grid">
              {projectSelected &&
                projectSelected.Imagenes.map((data, index) => (
                  <img src={data} alt="Gallery 1" />
                ))}
            </div>
          </section>
        </div>
      )}
    </motion.div>
  );
};

export default ViewProject;
