import "../Styles/Proyectos.css";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Projects from "../Data/Projects.json";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Proyectos() {
  return (
    <div>
      <section>
        <ProyectosTextoInicial />
      </section>
      <section>
        <GaleriaProyectos />
      </section>
    </div>
  );
}

function ProyectosTextoInicial() {
  const { t } = useTranslation();
  return (
    <div className="SectionInicial-proyectos">
      <section id="Section-texto-proyectos">
        {" "}
        <motion.h1
          initial={{ opacity: 0.3, y: 10, scale: 0.5 }}
          whileInView={{ opacity: 2, y: 0, scale: 1 }}
          transition={{ duration: 1.3 }}
        >
          {t("Nosotros.0.msjTituloProyecto")}
        </motion.h1>
        <p>{t("Nosotros.0.msjClickVerProyectos")}</p>
      </section>
    </div>
  );
}

const ImagenesData = [
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita1",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita2",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita3",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita4",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita5",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
  {
    img: "Imagenes/header3.jpeg",
    nombre: "uvita",
    Imagenes: [
      "Imagenes/A_colores.png",
      "Imagenes/V_colores.png",
      "Imagenes/header.jpg",
    ],
  },
];

function GaleriaProyectos() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language
  const [currentIndexes, setCurrentIndexes] = useState(
    Array(ImagenesData.length).fill(0)
  );

  const nextImage = (index) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((imgIndex, i) =>
        i === index
          ? (imgIndex + 1) % ImagenesData[index].Imagenes.length
          : imgIndex
      )
    );
  };

  const prevImage = (index) => {
    setCurrentIndexes((prevIndexes) =>
      prevIndexes.map((imgIndex, i) =>
        i === index
          ? (imgIndex - 1 + ImagenesData[index].Imagenes.length) %
            ImagenesData[index].Imagenes.length
          : imgIndex
      )
    );
  };
  return (
    <div>
      <ul className="Lista-Proyectos">
        {Projects.projects.map((data, index) => (
          <ChanceImageAction2
            data={data}
            index={index}
            currentLanguage={currentLanguage}
          />
        ))}
      </ul>
    </div>
  );
}

function ChanceImageAction({ data, index, currentImageIndex, onPrev, onNext }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const CloseContainer = () => {
    setIsExpanded(false);
  };

  const OpenContainer = () => {
    setIsExpanded(true);
  };

  return (
    <li id="li-img-proyectos" key={index}>
      <div
        onClick={OpenContainer}
        className={isExpanded === true ? "ActiveimgProyecto" : ""}
      >
        {isExpanded && (
          <>
            <img
              key={index}
              src={data.Imagenes[currentImageIndex]}
              alt="Project"
            ></img>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              onClick={(e) => {
                e.stopPropagation();
                CloseContainer();
              }}
              class="bi bi-x-lg Btn-Close-img-proyectos"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-compact-left Btn-Anterior-img-proyectos"
              viewBox="0 0 16 16"
              onClick={onPrev}
            >
              <path
                fill-rule="evenodd"
                d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-compact-right Btn-Siguiente-img-proyectos"
              viewBox="0 0 16 16"
              onClick={onNext}
            >
              <path
                fill-rule="evenodd"
                d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
              />
            </svg>
          </>
        )}
        {!isExpanded && <img key={index} src={data.img} alt="Project"></img>}
      </div>
    </li>
  );
}

function ChanceImageAction2({ data, index, currentLanguage }) {
  const navigate = useNavigate();
  const [isTouch, setIsTouch] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null); // Track the timeout ID
  const { t } = useTranslation();
  const addBackGround = () => {
    setIsTouch(true);
  };

  const removeBackGround = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to update the state after 3 seconds
    const id = setTimeout(() => {
      setIsTouch(false);
    }, 500); // 3000 milliseconds = 3 seconds

    setTimeoutId(id); // Save the timeout ID for potential clearing
  };

  return (
    <li
      id="li-img-proyectos"
      key={index}
      onTouchStart={addBackGround}
      onTouchEnd={removeBackGround}
    >
      <img
        src={data.img}
        alt="Project"
        onClick={() => {
          navigate({
            pathname: "/ViewProject",
            search: `?id=${encodeURIComponent(data.ID)}`,
          });
        }}
      />
      <div
        className="overlay-proyecto-images"
        style={{ opacity: isTouch ? "1" : "0" }}
      >
        <h2 id="nombre-proyecto" style={{ color: "white" }}>
          {t("ViewProject.0.msjProyectoEn")} {data.ubicacion[currentLanguage]}
        </h2>
      </div>
    </li>
  );
}

export default Proyectos;
